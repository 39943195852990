import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { HeadingMd } from '../components/heading/Heading'
import { Layout } from '../components/layout/Layout'
import { SiteHeader } from '../components/site-header/SiteHeader'
import { SiteFooter } from '../components/site-footer/SiteFooter'
import { Hero } from '../components/hero/Hero'
import { CtaButtons } from '../components/cta-buttons/CtaButtons'
import { TextBanner } from '../components/text-banner/TextBanner'
import { Cards } from '../components/cards/Cards'

const IndexPage = () => {
  return (
    <Layout title="Homepage" desc="Goede mondhygiëne is erg belangrijk voor een mooi en gezond gebit, regelmatig bezoek aan de mondhygiënist draagt hieraan bij.">
      <SiteHeader />
      <Hero
        image={
          <StaticImage
            src="../images/goby-UlfaDJ-M1to-unsplash.jpg"
            placeholder="blurred"
            alt=""
            loading="eager"
            formats={['auto', 'webp', 'avif']}
          />
        }
        title="Welkom bij Mondhygiëniste Praktijk Lambooy-Buchner"
        text="De mondhygiënist richt zich op preventie en helpt bij het voorkomen van tandbederf en tandvleesproblemen"
        button={{ title: 'Bekijk onze behandelingen', url: '/behandelingen' }}
      />

      <TextBanner>
        <HeadingMd h={2}>Goede mondhygiëne is erg belangrijk voor een mooi en gezond gebit</HeadingMd>
        <p>
          Regelmatig bezoek aan de mondhygiënist draagt hieraan bij. De mondhygiënist richt zich op preventie en helpt
          bij het voorkomen van tandbederf en tandvleesproblemen. U kunt onder andere terecht bij de mondhygiënist om:
        </p>
        <ul>
          <li>Een slechte adem op te lossen</li>
          <li>Aanslag / verkleurde tanden</li>
          <li>Bloedend tandvlees te verhelpen</li>
          <li>Tandplak en tandsteen te verwijderen</li>
          <li>Advies te krijgen over mondverzorging</li>
          <li>Fluoride aan te brengen</li>
          <li>Vullingen te polijsten</li>
        </ul>
      </TextBanner>

      <CtaButtons />

      <Cards />

      <SiteFooter />
    </Layout>
  )
}

export default IndexPage
